import {Route, Switch} from "react-router-dom";
import {Dashboard} from "./dashboard";
import {RefillsPage} from "./refills_page";
import {RecentPickingsPage} from "./recent_pickings_page";
import {PickingsPage} from "./pickings_page";
import NewSchedulingPage from "./new_scheduling_page";
import {PickingDetail} from "./picking_detail";
import {VendingMachineList} from "./vending_machine_list";
import {VendingMachines} from "./vending_machines";
import {VendingMachineSetting} from "./vending_machine";
import {VendingMachineStats} from "./vending_machine_stats";
import {InventoryRedirect} from "./inventory_redirect";
import {UserSettingsPage} from "./user_settings_page";
import {PageNotFound} from "./page_not_found";
import React from "react";

export function DriverSwitchRoute() {
    return <Switch>
        <Route exact path="/" component={Dashboard} />

        <Route exact path="/refills" component={RefillsPage} />
        <Route exact path={"/pickings/recent"} component={RecentPickingsPage} />
        <Route exact path={"/pickings"} component={PickingsPage} />

        <Route exact path={"/scheduling"} component={NewSchedulingPage} />

        <Route exact path="/pickings/:id" component={PickingDetail} />

        {/* this is for vending machine list */}
        <Route exact path="/vending_machine_list" component={VendingMachineList} />

        {/* this is vending machine settings */}
        <Route exact path="/vending_machines" component={VendingMachines} />

        <Route path="/vending_machines/:uuid/setting"
               component={VendingMachineSetting} />
        <Route path="/vending_machines/:uuid" component={VendingMachineStats} />
        <Route exact path="/inventories" component={InventoryRedirect} />
        <Route exact path="/settings" component={UserSettingsPage} />
        <Route path={"/"} component={PageNotFound} />
    </Switch>
}