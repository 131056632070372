import {Route, Switch} from "react-router-dom";
import VendingMachinePlanogram from "./vending_machine_planogram";
import CoilsStatsDataTable from "./coils_stats_data_table";
import {RefillsComponent} from "./refills_component";
import React from "react";
import {IngredientMapping, ProductMapping, Refill, VendingMachine} from "./models";
import {NavTab, RoutedTabs} from 'react-router-tabs';
import {TransactionsComponent} from "./transactions_component";

interface Props {
    vending_machine_name:string | null
    match: any
    vending_machine: VendingMachine | null
    online: boolean | null
    setManualBalance(coil: ProductMapping, newStockLevel: number)
    reloadCoils()
    coils: Array<ProductMapping>
    loadVendingMachineDetail()
    refills: Array<Refill>
    refillTime: string
    ingredientMappings : Array<IngredientMapping>
}

export function DriverVendingMachineStats(props: Props) {
    return  (<section className="content-header">
            <h1>
                Vending Machine Stats
                [
                <span>{props.vending_machine_name}</span>
                ]
            </h1>

            {props.vending_machine && props.vending_machine.archived ?
                <div className="alert alert-danger"><b>Note: </b> This vending machine is archived.</div> : null}

            {props.vending_machine && (props.vending_machine.installedTelemetryDeviceUuid == null) ?
                <div>
                    <br/>
                    <div className="alert alert-warning">
                        <b>Warning: </b> this vending machine does not have telemetry device attached.
                    </div>
                </div>

                :
                null
            }


            {
                props.online != null ?
                    (
                        <p className={(props.online ? "text-success" : "text-warning") + " text"}>
                            <i className={"fa fa-circle"}>&nbsp;</i>
                            {props.online ? "Online" : "Offline"}
                        </p>
                    )
                    :
                    null
            }

            <RoutedTabs startPathWith={`/vending_machines/${props.match.params.uuid}`}>
                <NavTab exact to={`/coils`}>
                      <span className="hidden-xs">
                        Stock &nbsp;
                      </span>
                    <i className="fa fa-layer-group"></i>
                </NavTab>
                <NavTab exact to={`/refills`}>
                      <span className="hidden-xs">
                        Refills &nbsp;
                      </span>
                    <i className="fa fa-truck"></i>
                </NavTab>
                <NavTab exact to={`/sales`}>
                      <span className="hidden-xs">
                        Sales &nbsp;
                      </span>
                    <i className="fa fa-line-chart"></i>
                </NavTab>
            </RoutedTabs>

            <Switch>
                <Route exact path={`/vending_machines/:uuid/sales`}>
                    <TransactionsComponent
                        vendingMachine={props.vending_machine}
                    />
                </Route>
                <Route exact path={`/vending_machines/${props.match.params.uuid}/planogram`}>
                    {props.vending_machine &&
                    <VendingMachinePlanogram vendingMachine={props.vending_machine}/>
                    }
                </Route>
                <Route exact path={`/vending_machines/${props.match.params.uuid}/coils`}>
                    {props.vending_machine &&
                    <CoilsStatsDataTable vendingMachine={props.vending_machine}
                                         manualBalance={props.setManualBalance}
                                         reloadProductMappings={props.reloadCoils}
                                         coils={props.coils}
                                         ingredientMappings={props.ingredientMappings}
                    />
                    }
                </Route>

                <Route exact path={`/vending_machines/${props.match.params.uuid}/refills`}>
                    {props.refills ?
                        <RefillsComponent refills={props.refills}
                                          refillsShouldReload={props.loadVendingMachineDetail}/>
                        :
                        null
                    }
                </Route>
                <Route exact path={`/vending_machines/${props.match.params.uuid}`}>
                    {props.vending_machine &&
                    <CoilsStatsDataTable vendingMachine={props.vending_machine}
                                         manualBalance={props.setManualBalance}
                                         reloadProductMappings={props.reloadCoils}
                                         coils={props.coils}
                                         ingredientMappings={props.ingredientMappings}
                    />
                    }
                </Route>
            </Switch>
        </section>
    );
}